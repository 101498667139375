.App {
  text-align: center;
}

.App {
  background-color: #282c34;
  height: 100vh;
  min-height:1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(2px + 2vmin);
  color: white;
  overflow: auto;
  /* padding-bottom:60px; */
}

/* Hide the scrollbar for the carousel */
::-webkit-scrollbar {
  overflow: hidden;
}

/* .resolved {
  filter: blur(0px) !important;
} */

/* .hintContainer {
  background-color: rgba(255, 255, 255, .15); 
  backdrop-filter: blur(5px);
} */
/* .Footer {

} */